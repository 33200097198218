.InteractiveCanvas {
  position: relative;
  overflow: hidden;
}

.project-image-form-toggle {
  z-index: 999;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.canvas-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 15px;
  color: white;
  text-shadow: 0 1px #222;
}

.btn.edit-submission {
  position: absolute;
  bottom: 15px;
  right: 242px;
  width: auto;
  min-width: 0;
  padding: 9px 10px;
}

.btn.shape-reset {
  position: absolute;
  bottom: 15px;
  right: 300px;
  width: auto;
  min-width: 0;
  padding: 9px 10px;
}

.InteractiveCanvas .canvas-zoom {
  position: absolute;
  top: 15px;
  right: 15px;
}

.canvas-picture-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.InteractiveCanvas .project-details__right {
  background-position: top center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  min-height: 500px;
  background: #f7f7f7;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
