.Auth-wrapper {
  background: white;
}

.Auth {
  min-height: 100vh;
  font-family: ProximanovaRegular, sans-serif;
  width: 95%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.Auth-row {
  min-height: 100%;
}

.Auth-col {
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  color: white;
}

.Auth-col-body h5 {
  text-transform: uppercase;
  font-family: ProximanovaBold, sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 25px;
  margin-bottom: 0;
}

.Auth-col-body h3 {
  color: #333333;
  font-weight: 700;
  letter-spacing: -4px;
  font-size: 75px;
  line-height: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
}

.Auth-col-body p {
  margin-top: 0;
  margin-bottom: 45px;
  color: rgb(0, 5, 26);
  font-size: 22px;
  letter-spacing: -1px;
  line-height: 35px;
  font-weight: 400;
  text-align: center;
}

.Auth-img img {
  width: 100%;
  height: auto;
}

.Auth-col-body {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.Auth-form {
  margin-top: 15px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
}

.Auth-form input[type="text"],
.Auth-form input[type="password"],
.Auth-form input[type="email"] {
  font-size: 15px;
  font-weight: 400;
  display: block;
  width: 100%;
  padding: 18px 35px;
  line-height: 21px;
  color: rgb(51,51,51);
  background-color: rgb(240, 242, 244);
  border: none;
  border-radius: 90px;
  margin-bottom: 15px;
}

.Auth-form-obligatory {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 25px;
  color: black;
}
.Auth-form-warning {
  margin-bottom: 20px;
}
.Auth-form-warning p{
  font-size: 14px;
  font-weight: 600;
  color: rgb(15, 104, 234);
  line-height: 120%;
  margin: 10px;
  text-align: left;
}
.Auth-form-buttons-row {
  display: flex;
  align-items: center;
}

.Auth-form-arrowbutton {
  display: flex;
  align-items: center;
  padding: 15px;
  border: none;
  border-radius: 90px;
  background: rgb(15, 104, 234);
  box-shadow: none;
  font-size: 17px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  cursor: pointer;
}
.Auth-form-submit {
  padding: 15px 45px;
  border: none;
  border-radius: 90px;
  background: rgb(15, 104, 234);
  box-shadow: none;
  font-size: 17px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 26px;
  width: 100%;
  color: white;
  cursor: pointer;
  height: auto;
}

.Auth-form-redirect {
  text-align: center;
  font-size: 16px;
  color: #00051a;
  line-height: 20px;
  font-weight: 400;
}

.Auth-form-redirect a {
  color: rgb(15, 104, 234);
  cursor: pointer;
  font-weight: 600;
}

.Auth-form-redirect:last-of-type {
  margin-top: 30px;
}

.Auth-checkbox label {
  font-family: Proximanova, sans-serif;
  opacity: .7;
  color: #2d323e;
  font-weight: 500;
}

.Auth-checkbox label a {
  color: #2d323e;
  text-decoration: underline;
}

.Auth-footer {
  background: rgb(0, 5, 26);
  padding-top: 60px;
  padding-bottom: 60px;
}

.Auth-footerInner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.Auth-footerCols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Auth-footerMenu {
  display: flex;
  flex-flow: column nowrap;
}

.Auth-footerMenuTitle {
  font-weight: 600;
  line-height: 30px;
  color: white;
  margin-bottom: 15px;
  font-size: 18px;
}

.Auth-footerMenuLink {
  color: white;
  padding: 8px 0;
  font-size: 18px;
  font-weight: 600;
  opacity: 0.75;
}

.Auth-postFooter {
  color: white;
  text-align: center;
  margin-top: 120px;
}

.Auth-social {
  border-bottom: 1px solid hsla(0,0%,100%,.2);
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.Auth-social a {
  color: white;
}

.Auth-social svg {
  margin: 0 15px;
}

.Auth-postFooterLogo {
  display: none;
}

@media (min-width: 992px) {
  .Auth-col {
    min-height: 100vh;
  }
}

@media screen and (max-width: 1200px) {
  .Auth {
    grid-template-columns: 1fr;
    max-width: 100vw;
    min-height: 90vh;
  }

  .Auth-col:first-of-type {
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .Auth-col-body {
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .Auth-col:last-of-type {
    min-height: 20vh;
  }

  .Auth-img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Auth-img img {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .Auth-footer {
    padding: 30px;
  }

  .Auth-footerMenuLink,
  .Auth-footerMenuTitle {
    font-size: 14px;
    line-height: 18px;
  }

  .Auth-footerCols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .Auth-footerCol:first-of-type {
    display: none;
  }

  .Auth-postFooter {
    margin-top: 120px;
    position: relative;
  }

  .Auth-postFooterLogo {
    display: block;
    position: absolute;
    top: -80px;
    left: 0;
  }

  .Auth-col-body h3 {
    font-size: 52px;
    letter-spacing: -2px;
    text-align: center;
  }
}