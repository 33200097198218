a.no-style {
  color: inherit;
  text-decoration: none;
}

.floorplan-container .project-details__right {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}

.pickr {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pickr .pcr-button {
  height: 16px;
  width: 16px;
}

.project-card-image {
  height: 350px;
  width: 100%;
  background-size: cover;
  position: top center;
  background-repeat: none;
}

.planpoint-embed {
  min-height: 700px;
}

img.icon {
  background: none;
}

.modal-wide {
  max-width: 620px;
}
