.marker {
  background-image: url('../../../../assets/planpoint_logo_white.svg');
  background-size: 75%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #2418ab;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}